<template>
<Page title="案例">
  <div name="Caselist">
    <div class="top">
      <div class="topMain">
        <div class="logo"> </div>
        <div class="topRig"> </div>
      </div>
    </div>
    <div class="wbg">
      <div class="wrapper">
        <div class="clearfix">
          <div class="sidebar">
            <div class="sdbox">
              <h4><span class="sdtit01">检索导航-案例类型</span></h4>
              <div>
                <ul>
                  <Collection  name="recase_type"  v-slot="{ collection }"
                               :query="{  }">
                    <li v-for="value in collection" :key="value.item_value" :class="`casetype box`+value.item_value"><a-icon type="folder-open" /><router-link :to="`/case/list/${value.item_value}`">{{value.item_name}}</router-link></li>
                  </Collection>
                </ul>
              </div>
            </div>
          </div>
          <div class="content">
            <Casemap></Casemap>
          </div>
        </div>
      </div>
    </div>
  </div>
</Page>
</template>
<script>
import Collection from '@/components/Collection'
import Casemap from '../../components/base/Casemap.vue'
export default {
  name: 'Casebase',
  components: {
    Collection,
    Casemap
  },
  data () {
    return {
      title: '案件',
      id: this.$route.params.id,
      recase_title: '',
      provider: {
        current: 1,
        defaultPageSize: 12,
        limit: 1
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = this.provider.limit = page
    }
  },
  activated () {
    this.id = this.$route.params.id
  },
  watch: {
    '$route.params.id': function (id) {
      this.id = id
    }
  }
}
</script>
<style scoped src="../../assets/css/case.css"></style>
